<template>
  <div>
    <v-dialog
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="show"
      max-width="650px"
      persistent
    >
      <div class="text-center" v-if="loading" style="min-height: 500px">
        <v-progress-circular
          :size="100"
          :width="10"
          style="
            text-align: center;
            padding-top: 20px;
            padding-bottom: 20px;
            margin-top: 200px;
          "
          color="#7253CF"
          indeterminate
        ></v-progress-circular>
      </div>

      <v-card background-color="#f2f2f2" v-if="!loading">
        <v-card-title
          class="py-0 pt-5"
          style="background-color: #7253cf; color: white"
        >
          <div class="d-flex px-1">
            <img
              src="@/assets/basketLogoDialogDistrictManagement.svg"
              height="85"
              width="100"
            />
          </div>
          <div class="ml-1 dialogTitle-text">Player Availability</div>
          <v-spacer></v-spacer>
          <v-icon
            text
            size="27"
            color="white"
            style="
              cursor: pointer;
              position: relative;
              right: -15px;
              bottom: 40px;
            "
            @click="
              togglePlayerAvailabilityEventModal({ show: false });
              closeCallBackModal();
            "
            >mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="pb-0">
          <!--   <v-col cols="12" class="px-2">
                <v-text-field
                  label="Task Title"
                  outlined
                  dense
                  class="formFields"
                  color="#7253CF"
                >
                </v-text-field>
              </v-col>-->
          <v-row>
            <v-col cols="4" class="pl-2" style="margin-top: 10px;">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    dense
                    v-model="formatedStartDate"
                    prepend-inner-icon="mdi-calendar-range"
                    label="Date"
                    v-bind="attrs"
                    readonly
                    color="#7253CF"
                    class="formFields"
                    v-on="on"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="start_date"
                  no-title
                  @input="menu = false"
                  @change="getEventSlotList"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <!--  <v-col cols="4" class="pl-2">
              <v-btn
                color="#38227A"
                dark
                class="text-capitalize px-7 mr-5"
                @click="getEventSlotDetailList()"
                height="45px"
                 ref="checkSlotsButton"
                style="border-radius: 10px; height: 40px"
                v-if="!this.addFlag" >Check Slots</v-btn
              >
            </v-col>-->
          </v-row>
          <div class="table-div">
            <template>
              <label
                style="margin-left: 26%; font-size: 18px"
                v-if="this.showCount"
                >Available Event Slots({{ this.arrayCount1 }})</label
              >
              <label style="margin-left: 26%; font-size: 18px" v-else
                >Available Event Slots({{ this.arrayCount }})</label
              >
              <br />
              <v-row v-for="(item, index) in slotDetailList" :key="index">
                <v-col cols="12" class="pl-2">
                  <v-text-field
                    :label="'Event' + (index + 1)"
                    v-model="item.note"
                    outlined
                    dense
                    class="formFields"
                    color="#7253CF"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-col cols="4" class="pl-2">
                <v-btn
                  color="#38227A"
                  dark
                  class="text-capitalize px-7 mr-5"
                  height="45px"
                  style="border-radius: 10px; height: 40px"
                  @click="submitEventSlotDetail()"
                  v-if="this.type=='add'"
                  >Submit</v-btn
                >
                <v-btn
                  color="#38227A"
                  dark
                  class="text-capitalize px-7 mr-5"
                  height="45px"
                  style="border-radius: 10px; height: 40px"
                  @click="editEventSlotDetail()"
                  v-if="this.type=='edit'"
                  >Submit</v-btn
                >
              </v-col>
            </template>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import {
  API_PLAYER_AVAILBLE_EVENT_SLOT_POST,
  API_PLAYER_AVAILBLE_EVENT_SLOT_POST_DETAIL,
  API_PLAYER_AVAILBLE_EVENT_SLOT_PATCH_DETAIL,
  API_PLAYER_AVAILBLE_EVENT_SLOT_LIST,
  API_PLAYER_AVAILBLE_EVENT_SLOT_DETAIL_LIST,
  API_PLAYER_AVAILBLE_EVENT_SLOT_GET,
  API_PLAYER_AVAILBLE_EVENT_SLOT_PATCH,
} from "@/constants/APIUrls";
import Axios from "@/api/BaseAxios";
import moment from "moment";
import {
  PAGE_INFO,
  ITEMS_PER_PAGE,
  PAGE_NUMBER,
  TOTAL_PAGE,
} from "@/constants/APIKeys";
import { ITEMS, LOADING } from "@/constants/ModuleKeys";
export default {
  name: "ListCalendarManagement",
  components: {},
  data() {
    return {
      loading: false,
      formLoading: false,
      nameFlag: false,
      campaignData: {},
      menu: false,
      menu1: false,
      start_date: "",
      end_date: "",
      players_user_id_lists: [],
      arrayCount: 0,
      arrayCount1: 0,
      slotId: "",
      note: "",
      slotID1: "",
      eventCount: 0,
      result: 0,
      addFlag: true,
      showCount: false,
      slotDetailList: [],
      campaignDataTable: {
        headers: [
          {
            width: "10%",
            sortable: false,
            text: "Player Name",
            align: "center",
            style: "font-size: 16px;",
            class: ["tableHeader-text", "tableHeader-bg"],
          },
        ],
        [ITEMS]: [],
        [LOADING]: true,
        [PAGE_INFO]: {
          [PAGE_NUMBER]: 1,
          [TOTAL_PAGE]: 15,
          [ITEMS_PER_PAGE]: 10,
        },
        itemsPerPage: [10, 15, 20, 30, 50, 100],
      },
    };
  },
  computed: {
    ...mapGetters({
      showGetter: "calendarManagement/getPlayerAvailabiltyEvent",
    }),
    selectedCampaignData() {
      return this.selectedCampaign;
    },
    layout() {
      return this.$route.meta.layout;
    },
    show: {
      get() {
        return this.showGetter;
      },
      set(value) {
        return this.togglePlayerAvailabilityEventModal({ show: value });
      },
    },
    type() {
      return this.$store.state.calendarManagement.playerAvailabilityEvent.type;
    },
    date() {
      return this.$store.state.calendarManagement.playerAvailabilityEvent.date;
    },
    playerID() {
      return this.$store.state.calendarManagement.playerAvailabilityEvent
        .playerID;
    },
    toastMessage() {
      if (this.type === "add") {
        return "Task Added";
      } else {
        return "Task Updated";
      }
    },
    dialogTitle() {
      switch (this.type) {
        case "add":
          return "Add New Task";
        case "edit":
          return "Edit Event";
        default:
          return "";
      }
    },
    formatedStartDate: {
      get() {
        if (this.start_date) {
          return moment(this.start_date).format("MM-DD-YYYY");
        } else {
          return "";
        }
      },
      set() {
        return "";
      },
    },
    formatedEndDate: {
      get() {
        if (this.end_date) {
          return moment(this.end_date).format("YYYY-MM-DD");
        } else {
          return "";
        }
      },
      set() {
        return "";
      },
    },
    dynamicSlotDetailList() {
      // Generate a new array based on the arrayCount value
      const newArray = [];
      for (let i = 0; i < this.arrayCount; i++) {
        newArray.push({ note: "" }); // You can initialize with default values if needed
      }
      return newArray;
    },
    countNullNotes() {
      return this.slot_detail_list.reduce(
        (count, slot) =>
          count +
          slot.event_slot_details.filter((detail) => detail.note === null)
            .length,
        0
      );
    },
  },

  watch: {
    show(value) {
      if (value) {
        // Modal open callback
        this.openCallBackModal();
      } else {
        // Modal close callback
        this.closeCallBackModal();
      }
    },
    arrayCount() {
      // Update the slotDetailList when arrayCount changes
      this.slotDetailList = this.dynamicSlotDetailList;
    },
  },
  methods: {
    ...mapActions({
      // sponsor Modal
      togglePlayerAvailabilityEventModal:
        "calendarManagement/togglePlayerAvailabilityEvent",
      showToast: "snackBar/showToast",
    }),
    openCallBackModal() {
    if(this.date && this.type=='add')
    {
    this.start_date=this.date;
    this.getEventSlotList()
    }
      if (this.type == "edit") {
        this.start_date = this.date;
        this.getEventSlotList();
       //this.getEventSlotDetail();
      }
      console.log("open modal");
    },
    closeCallBackModal() {
      (this.campaignDataTable.items = []),
        (this.start_date = ""),
        (this.end_date = ""),
        (this.nameFlag = false);
    },
    reloadFunction() {
      location.reload();
    },
    submitEventSlot() {
      this.formLoading = true;
      const successHandler = (res) => {
        console.log(res);
      //  location.reload();
        this.showToast({
          message: "Updated successfully.",
          color: "s",
        });
        this.formLoading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.showToast({
          message: res,
          color: "e",
        });
        this.formLoading = false;
      };
      let formData = {};
       formData["id"] = this.playerID;
      formData["start_date"] = this.start_date;
      formData["note"] = this.note;
      formData["players"] = this.players_user_id_lists;
      Axios.request_POST(
        API_PLAYER_AVAILBLE_EVENT_SLOT_POST,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },

    getEventSlotList() {
      const self = this;
      const successHandler = (res) => {
        if (res.data.slot_list.length != 0) {
        if(this.type=='add'){
          self.slotList = res.data.slot_list;
          }
          self.players = res.data.slot_list[0].players;
          self.arrayCount = self.players.length;
          self.slotId = res.data.slot_list[0].id;
          self.arrayCount1 = res.data.slot_list[0].empty_slot_count;
          self.eventCount = res.data.slot_list[0].event_slot_detail_count;
          if (res.data.slot_list[0].event_slot_detail_count == 0) {
            self.addFlag = true;
          } else {
            self.addFlag = false;
            if(this.type=='add'){
            this.getEventSlotDetailList();
            }
           
          }
          if(this.type=='edit')
          {this.getEventSlotDetail();}
        }
        self.result = self.arrayCount - self.eventCount;
      };
      const failureHandler = (res) => {
        console.log(res);
        self.loading = false;
      };
      const finallyHandler = () => {};
      const formJson = {};
      formJson["start_date"] = this.start_date;
      formJson["id"] = this.playerID;
      return Axios.request_GET(
        API_PLAYER_AVAILBLE_EVENT_SLOT_LIST,
        formJson,
        {},
        successHandler,
        failureHandler,
        false,
        finallyHandler
      );
    },
    submitEventSlotDetail() {
      this.formLoading = true;
      const successHandler = (res) => {
        console.log(res);
        location.reload();
        this.showToast({
          message: "Updated successfully.",
          color: "s",
        });
        this.formLoading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.showToast({
          message: res,
          color: "e",
        });
        this.formLoading = false;
      };

      const slotDetails = this.slotDetailList.map((item) => ({
        slot: this.slotId,
        note: item.note,
      }));
      const requestBody = slotDetails;

      Axios.request_POST(
        API_PLAYER_AVAILBLE_EVENT_SLOT_POST_DETAIL,
        requestBody,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    editEventSlotDetail() {
      // alert("dsada");
      this.formLoading = true;
      const successHandler = (res) => {
        console.log(res);
     location.reload();
        this.showToast({
          message: "Updated successfully.",
          color: "s",
        });
        this.formLoading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.showToast({
          message: res,
          color: "e",
        });
        this.formLoading = false;
      };

      const slotDetails = this.slotDetailList.map((item) => ({
        id: item.id,
        slot: this.playerID,
        note: item.note,
      }));
      const requestBody = slotDetails;

      Axios.request_PATCH(
        API_PLAYER_AVAILBLE_EVENT_SLOT_PATCH_DETAIL,
        requestBody,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
     editEventSlotPatch() {
       // alert("dsada");
      this.formLoading = true;
      const successHandler = (res) => {
        console.log(res);
      //  location.reload();
        this.showToast({
          message: "Updated successfully.",
          color: "s",
        });
        this.formLoading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.showToast({
          message: res,
          color: "e",
        });
        this.formLoading = false;
      };

      const slotDetails = this.slotDetailList.map((item) => ({
        id: item.id,
        slot: this.playerID,
        note: item.note,
      }));
      const requestBody = slotDetails;

      Axios.request_PATCH(
        API_PLAYER_AVAILBLE_EVENT_SLOT_PATCH,
        requestBody,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getEventSlotDetailList() {
      const successHandler = (res) => {
        this.showCount = true;
        if(res.data.slot_detail_list.length!=0)
        {
        this.slotDetailList = res.data.slot_detail_list[0].event_slot_details;
        this.note = res.data.slot_detail_list[0].note;
        this.slotID1 = res.data.slot_detail_list[0].event_slot_details[0].slot;
      };
      }
      const failureHandler = (res) => {
        console.log(res);
        self.loading = false;
      };
      const finallyHandler = () => {};
      const formJson = {};
      formJson["start_date"] = this.start_date;
       formJson["id"] = this.playerID;
      return Axios.request_GET(
        API_PLAYER_AVAILBLE_EVENT_SLOT_DETAIL_LIST,
        formJson,
        {},

        successHandler,
        failureHandler,
        false,
        finallyHandler
      );
    },
     getEventSlotDetail() {
      const successHandler = (res) => {
       this.showCount = true;
        this.slotDetailList=res.data.event_slot.event_slot_details;
       this.arrayCount1=res.data.event_slot.empty_slot_count;
       this.arrayCount=res.data.event_slot.players.length;
      };
      const failureHandler = (res) => {
        console.log(res);
        self.loading = false;
      };
      const finallyHandler = () => {};
      const formJson = {};
      formJson["id"] = this.playerID;
      return Axios.request_GET(
        API_PLAYER_AVAILBLE_EVENT_SLOT_GET,
        formJson,
        {},

        successHandler,
        failureHandler,
        false,
        finallyHandler
      );
    },
  },
};
</script>
<style scoped>
.formFields {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  /* identical to box height */

  color: #757575;
}
.dialogTitle-text {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 315%;
  color: #ffffff;
}

.dialogAction-btnText >>> span {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 161.5%;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: none !important;
}
</style>
